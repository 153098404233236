<template>
  <Disclosure as="nav" :class="headerClasses">
    <div class="mx-auto max-w-[100%] h-[100%] header_media">
      <div class="flex h-[100%] justify-between">
        <div class="flex flex-shrink-0 items-center">
          <img
            v-if="!isNotFound"
            class="w-[159px] h-[65px] top-[15px]"
            src="../../assets/logo/Name.png"
            alt="Filedgr"
          />
          <img
            v-else
            class="w-[159px] h-[65px] top-[15px]"
            src="../../assets/NotFound/logo.svg"
            alt="Filedgr"
          />
        </div>

        <!-- Navigation Links -->
        <div
          class="middle_menus sm:ml-6 sm:flex sm:space-x-8 h-[91px] justify-center"
          :class="navClasses"
        >
          <!-- <router-link
            v-if="!isNotFound"
            to="/"
            :class="[
              'inline-flex items-center px-1 pt-1 text-[18px]  text-gray-900',
              { 'border-b-2 border-[#03162D] font-[700]': isActive('/') },
            ]"
          >
            Dashboard
          </router-link> -->
          <!-- <router-link
            v-if="!isNotFound"
            to="/templates"
            :class="[
              'inline-flex items-center px-1 pt-1 text-[18px] text-gray-900',
              {
                'border-b-2 border-[#03162D] font-[700]':
                  isActive('/templates'),
              },
            ]"
          >
            Templates
          </router-link>
          <router-link
            v-if="!isNotFound"
            to="/nfts"
            :class="[
              'inline-flex items-center px-1 pt-1 text-[18px] text-gray-900',
              {
                'border-b-2 border-[#03162D] font-[700]': isActive('/nfts'),
              },
            ]"
          >
            NFTs
          </router-link> -->

          <!-- Additional Links for 404 -->
          <template v-if="isNotFound">
            <a
              href="#"
              class="inline-flex items-center text-[16px] font-[700] text-gray-900"
            >
              About
            </a>
            <a
              href="#"
              class="inline-flex items-center text-[16px] font-[700] text-gray-900"
            >
              FAQs
            </a>
            <a href="#" class="inline-flex items-center text-[16px] font-[700]">
              Contact
            </a>
          </template>
        </div>


      </div>
    </div>

    <!-- Mobile Menu Content -->
    <DisclosurePanel
      class="bg-[white] z-[9999999] relative mobile_menus duration-300"
    >
      <div class="flex flex-row justify-between place-items-center pr-3 py-3">
        <div>
          <img
            class="w-[139px] h-[55px] top-[15px]"
            src="../../assets/logo/Name.png"
            alt="Filedgr"
          />
        </div>
        <div class="flex place-items-start">
          <DisclosureButton>
            <XMarkIcon class="block h-7 w-7" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
      <div class="space-y-1 pb-3 pt-2">
        <router-link to="/">
          <DisclosureButton
            class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
          >
            Dashboard
          </DisclosureButton>
        </router-link>
        <router-link to="/templates">
          <DisclosureButton
            class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
          >
            Templates
          </DisclosureButton>
        </router-link>
        <router-link to="/nfts">
          <DisclosureButton
            class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
          >
            NFT's
          </DisclosureButton>
        </router-link>
      </div>
      <div class="border-t border-gray-200 pb-3 pt-4">
        <div class="flex items-center px-4">
          <div class="flex-shrink-0">
            <img
              class="h-10 w-10 rounded-full"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">Tom Cook</div>
            <div class="text-sm font-medium text-gray-500">tom@example.com</div>
          </div>
          <button
            type="button"
            class="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span class="absolute -inset-1.5" />
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-3 space-y-1">
          <DisclosureButton
            as="a"
            href="#"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
            >Your Profile</DisclosureButton
          >
          <DisclosureButton
            as="a"
            href="#"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
            >Settings</DisclosureButton
          >
          <DisclosureButton
            as="a"
            href="#"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
            >Sign out</DisclosureButton
          >
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
export default {
  name: "Header",
  components: {
  },
  data() {
    return {
      Username: "Olivia Rhye",
    };
  },
  methods: {
    closeComponent() {
      this.$emit("close");
    },
  },
};
</script>

<script setup>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/vue";
import { BellIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
// const isActive = (path) => route.path === path;
const isNotFound = computed(() => route.name === "NotFound");
const headerClasses = computed(() =>
  isNotFound.value
    ? "w-[100%] h-[90px] bg-white mt-5 lg:px-[100px]"
    : "w-[100%] h-[90px] bg-[#E0F2EE] shadow lg:px-[100px]"
);
const navClasses = computed(() => (isNotFound.value ? "justify-start" : ""));
</script>

<style scoped>
/* Add your scoped styles here */
.burger_menu {
  display: none !important;
}

/* media */
@media (max-width: 1020px) {
  .header_media {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 991px) {
  .middle_menus {
    display: none !important;
  }
  .right_menus {
    display: none !important;
  }
  .burger_menu {
    display: flex !important;
  }

  /*mobile_menus */
  .mobile_menus {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 320px;
    height: 100vh;
    z-index: 9999999;
    overflow-y: auto;
  }
}

@media (max-width: 450px) {
  .mobile_menus {
    width: 100% !important;
    height: 100vh !important;
  }
}
</style>
