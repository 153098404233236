<template>
  <div class="bg-bodycolor h-auto flex flex-col items-center px-[100px] py-10">
    <div class="bg-bodycolor flex justify-between items-center py-2 w-[100%]">
      <h2 class="text-[26px] font-[500] text-white">Create New Template</h2>
      <div
        class="text-white bg-bodycolor px-4 py-2 rounded-[6px] border-[1px] border-[#FFFFFF] flex gap-2"
      >
        <img src="../../../assets/Template/ButtonIcon.svg" alt="" />
        <button class="w-[100%]">createNFT</button>
      </div>
    </div>
    <form class="w-[100%] flex flex-col gap-[20px]">
      <div
        class="bg-[#E0F2EE] w-[100%] h-auto flex flex-col mt-4 px-7 py-8 rounded-[12px]"
      >
        <div
          class="w-[100%] flex justify-between place-items-center gap-[23px]"
        >
          <div class="w-[100%]">
            <label class="text-[18px] font-[500]" v-if="ISTemplateName"
              >Template Name *</label
            >
            <select
              v-if="ISTemplateName"
              :class="{
                'w-[100%] h-[48px] rounded-[8px] border-[1px] border-[#C6D3F5] mt-3 px-3 py-2 outline-none': true,
                'custom-style-for-nft': ISTemplateName,
              }"
            >
              <option disabled selected>Template Name Here</option>
              <option>Template 1</option>
            </select>
            <label class="text-[18px] font-[500]" v-else>Product Name *</label>
            <input
              type="text"
              v-if="!ISTemplateName"
              :placeholder="'Asset 1'"
              :class="{
                'w-[100%] h-[48px] rounded-[8px] border-[1px] border-[#C6D3F5] mt-3 px-3 py-2': true,
                'custom-style-for-nft': ISTemplateName,
              }"
            />
          </div>

          <div v-if="IsVisible" class="w-[100%]">
            <label class="text-[18px] font-[500]">Headline *</label>
            <input
              type="text"
              placeholder="Turn your Donuts in to Dollars!!!"
              class="w-[100%] h-[48px] rounded-[8px] border-[1px] border-[#C6D3F5] mt-3 px-3 py-2"
            />
          </div>
        </div>
        <div class="flex flex-col w-[100%]">
          <div class="mt-6">
            <label class="text-[18px] text-[#01060D] font-[500]"
              >Description *</label
            >
            <textarea
              class="mt-[16px] w-[100%] h-[195px] px-3 py-2 bg-white border-[1px] border-[#C6D3F5] outline-none"
              placeholder="Description..."
            ></textarea>
          </div>
          <div class="mt-6">
            <label class="text-[18px] font-[500]"> Hint *</label>
            <textarea
              class="mt-[16px] w-[100%] h-[195px] px-3 py-2 bg-white border-[1px] border-[#C6D3F5] outline-none"
              placeholder="Hint..."
            ></textarea>
          </div>
        </div>
        <div class="flex justify-between mt-5">
          <div class="w-[100%]">
            <h2 class="text-[18px] font-[500]">Upload Product Image *</h2>
            <div class="flex gap-4 mt-4">
              <div
                class="h-[148px] w-[148px] bg-white border-1 border-dashed border-gray-300"
              >
                <img src="../../../assets/Template/images/image.png" />
              </div>
              <div
                class="h-[148px] w-[148px] bg-white border-1 border-dashed border-gray-300 flex justify-center items-center"
              >
                <img class="" src="../../../assets/Template/images/plus.png" />
              </div>
            </div>
            <p class="text-[12px] font-[400] mt-2 italic">
              jpg/png files with a size less than 500KB & min. dimensions of
              300px to 300px.
            </p>
          </div>
          <div class="w-[100%]">
            <h2 class="text-[18px] font-[500]">Template Package</h2>
            <div
              class="w-[137px] h-[35px] mt-[15px] bg-[#03B897] rounded-[6px] flex justify-center items-center"
            >
              <button class="text-[13px] font-[400] py-4 text-white">
                Click to upload
              </button>
            </div>
            <p class="mt-2 text-[12px] font-[400] italic">
              Zip files with a size less than 5000KB.
            </p>
            <div
              class="flex justify-between max-w-[287px] place-items-center mt-2"
            >
              <div class="flex place-items-center gap-2">
                <img
                  class="w-[14px] h-[14px]"
                  src="../../../assets/Template/document.svg"
                  alt="file"
                />
                <p class="text-[13px] text-[#273550] font-[400]">
                  element-plus-logo2.zip
                </p>
              </div>
              <div>
                <img
                  class="w-[12.25px] h-[12.25px]"
                  src="../../../assets/Template/vector.svg"
                  alt="vector"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="IsShowCustomFields"
        class="w-[100%] h-[380px] bg-[#E0F2EE] flex mt-3 px-7 py-8 rounded-[12px]"
      >
        <div class="flex flex-row w-[100%] py-2 gap-[18px]">
          <div class="flex flex-col max-w-[320px]">
            <div>
              <h1 class="text-[18px] font-[500] text-[#01060D]">
                Custom Fields *
              </h1>
              <select
                class="w-[100%] h-[48px] mt-3 rounded-[6px] px-4 outline-none"
              >
                <option class="">Data Stream X</option>
              </select>
            </div>
            <div>
              <h1 class="mt-3 text-[18px] font-[500] text-[#01060D]">
                Sub Type *
              </h1>
              <select
                class="w-[100%] h-[48px] mt-3 rounded-[6px] px-4 outline-none"
              >
                <option class="text-[14px] font-[500] text-[#273550]">
                  EW NFT
                </option>
              </select>
            </div>
            <div class="w-[100%] mt-5">
              <p class="text-[12px] font-[300] italic text-[#273550]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                maximus lectus ac mauris consequat, in luctus tellus
                pharetra.Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Etiam maximus
              </p>
            </div>
          </div>
          <div class="bg-white w-[100%] rounded-[6px] mt-3 px-7 py-8">
            <div class="flex flex-row w-[100%]">
              <div class="w-[240px]">
                <h2 class="text-[16px] font-[500] text-[#01060D]">
                  Main Image:
                </h2>
              </div>
              <div class="w-[100%]">
                <div
                  class="w-[137px] h-[35px] bg-[#03B897] rounded-[6px] flex justify-center items-center"
                >
                  <button class="text-[13px] font-[400] py-4 text-white">
                    Click to upload
                  </button>
                </div>
                <p class="mt-2 text-[12px] font-[400] italic">
                  Zip files with a size less than 5000KB.
                </p>
                <div
                  class="flex justify-between max-w-[287px] place-items-center mt-2"
                >
                  <div class="flex place-items-center gap-2">
                    <img
                      class="w-[14px] h-[14px]"
                      src="../../../assets/Template/document.svg"
                      alt="file"
                    />
                    <p class="text-[13px] text-[#273550] font-[400]">
                      element-plus-logo2.zip
                    </p>
                  </div>
                  <div>
                    <img
                      class="w-[12.25px] h-[12.25px]"
                      src="../../../assets/Template/vector.svg"
                      alt="vector"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-row place-items-center w-[100%] mt-20">
              <div class="w-[175px]">
                <h1 class="text-[16px] font-[500] text-[#01060D]">Tagline:</h1>
              </div>
              <div
                class="w-[360px] border-2 border-[#C6D3F5] h-[48px] rounded-[6px]"
              >
                <div class="w-[360px]">
                  <div class="p-3 text-[14px] font-[500] text-[#273550]">
                    <input
                      class="w-[100%] h-[100%] outline-none border-none"
                      type="text"
                      placeholder="Your Template Data"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="h-[356px] w-[100%] bg-[#E0F2EE] flex flex-col mt-3 px-7 py-8 rounded-[12px]"
      >
        <div class="flex flex-row w-[100%] gap-[24px]">
          <div class="flex flex-col w-[80%]">
            <div class="flex flex-row gap-2 place-items-center">
              <div class="flex">
                <h2 class="text-[18px] font-[500] text-[#0D0D0D]">
                  Data Streams
                </h2>
              </div>
              <div class="flex">
                <img src="../../../assets/Template/Icon.svg" alt="icon" />
              </div>
            </div>
            <div class="flex flex-col gap-5 mt-3">
              <input
                type="text"
                placeholder="Data Stream X"
                class="h-[48px] rounded-[6px] border-[1px] border-[#C6D3F5] placeholder:text-[#01060D] !text-[14px] font-[500] px-3"
              />
              <input
                type="text"
                placeholder="Data Stream X"
                class="h-[48px] rounded-[6px] border-[1px] border-[#C6D3F5] placeholder:text-[#01060D] !text-[14px] font-[500] px-3"
              />
              <input
                type="text"
                placeholder="Data Stream X"
                class="h-[48px] rounded-[6px] border-[1px] border-[#C6D3F5] placeholder:text-[#01060D] !text-[14px] font-[500] px-3"
              />
            </div>
          </div>
          <div class="flex flex-col w-[100%]">
            <div class="flex flex-row gap-2 place-items-center">
              <div class="flex">
                <h2 class="text-[18px] font-[500] text-[#0D0D0D]">Tokens</h2>
              </div>
              <div class="flex">
                <img src="../../../assets/Template/Icon.svg" alt="icon" />
              </div>
            </div>
            <div class="flex w-[100%] mt-3">
              <div class="flex flex-col gap-5 w-[100%]">
                <div
                  class="w-[100%] flex justify-start place-items-center gap-[20px]"
                >
                  <select
                    class="h-[48px] w-[80%] rounded-[6px] border-[1px] border-[#C6D3F5] px-3 text-[14px] font-[500] text-[#01060D]"
                  >
                    <option>Tokens X</option>
                  </select>
                  <div
                    class="flex flex-row gap-[35px] w-[25%] justify-center place-items-center"
                  >
                    <img
                      src="../../../assets/Template/bin.svg"
                      alt="bin"
                      class="w-[20px] h-[20px]"
                    />
                    <img
                      src="../../../assets/Template/edit.svg"
                      alt="edit"
                      class="w-[20px] h-[20px]"
                    />
                  </div>
                </div>
                <div
                  class="w-[100%] flex justify-start place-items-center gap-[20px]"
                >
                  <select
                    class="h-[48px] w-[80%] rounded-[6px] border-[1px] border-[#C6D3F5] px-3 text-[14px] font-[500] text-[#01060D]"
                  >
                    <option>Tokens X</option>
                  </select>
                  <div
                    class="flex flex-row gap-[35px] w-[25%] justify-center place-items-center"
                  >
                    <img
                      src="../../../assets/Template/bin.svg"
                      alt="bin"
                      class="w-[20px] h-[20px]"
                    />
                    <img
                      src="../../../assets/Template/edit.svg"
                      alt="edit"
                      class="w-[20px] h-[20px]"
                    />
                  </div>
                </div>
                <div
                  class="w-[100%] flex justify-start place-items-center gap-[20px]"
                >
                  <select
                    class="h-[48px] w-[80%] rounded-[6px] border-[1px] border-[#C6D3F5] px-3 text-[14px] font-[500] text-[#01060D]"
                  >
                    <option>Tokens X</option>
                  </select>
                  <div
                    class="flex flex-row gap-[35px] w-[25%] justify-center place-items-center"
                  >
                    <img
                      src="../../../assets/Template/bin.svg"
                      alt="bin"
                      class="w-[20px] h-[20px]"
                    />
                    <img
                      src="../../../assets/Template/edit.svg"
                      alt="edit"
                      class="w-[20px] h-[20px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex h-[40px] w-[100%] border-[1px] rounded-[6px] border-[#03162D] mt-5"
        >
          <button
            class="text-center w-[100%] text-[12px] text-[#03162D] font-[500]"
          >
            Add New Field
          </button>
        </div>
      </div>
      <div
        v-if="IsShowAttachmentNote"
        class="bg-[#E0F2EE] w-[100%] py-7 px-7 flex flex-col gap-5 rounded-[12px]"
      >
        <attachmentComp />
        <attachmentComp />
        <attachmentComp />
      </div>
    </form>
    <div class="flex flex-row w-[100%] gap-5 mt-5">
      <div class="w-[100%]">
        <button
          class="border-[1px] border-[#9B9B97] w-[100%] h-[50px] rounded-[6px] text-[#FFFFFF] font-[500] text-[18px]"
        >
          Save Draft
        </button>
      </div>
      <div class="w-[100%]">
        <button
          class="w-[100%] h-[50px] rounded-[6px] text-[#FFFFFF] font-[500] text-[18px] bg-[#10DAB9]"
        >
          Create Template
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import attachmentComp from "../../../components/NFTs/attachmentComp.vue";
export default {
  name: "TempCreate",
  components: {
    attachmentComp,
  },
  props: {
    ISTemplateName: {
      type: Boolean,
      default: false,
    },
    IsVisible: {
      type: Boolean,
      default: true,
    },
    IsShowCustomFields: {
      type: Boolean,
      default: true,
    },
    IsShowAttachmentNote: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
