<template>
  <div class="h-[276px] w-[100%] flex bg-[#E0F2EE] rounded-[12px]">
    <div class="w-[100%] py-5 px-7 flex">
      <div class="w-[100%]">
        <img
          class="w-[285.5px] h-[228px]"
          src="../../assets/NFTs/Nfts.svg"
          alt="NFTs"
        />
      </div>
      <div class="w-[100%]">
        <h1 class="text-[22px] text-[#01060D] font-[600]">{{ Nftname }}</h1>
        <p class="text-[12px] text-[#818EAD] font-[400] mt-2">{{ name }}</p>
        <div class="flex gap-2 place-items-center mt-2">
          <h1 class="text-[28px] text-[#01060D] font-[500]">{{ Token }}</h1>
          <h2 class="text-[12px] text-[#01060D] font-[400]">Token Required</h2>
        </div>
        <div class="w-[100%] mt-1">
          <p class="text-[#273550] text-[12px] font-[400]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
            maximus lectus ac mauris consequat, in luctus tellus pharetra.Lorem
            ipsum dolor sit amet, consectetur adipiscing elit. Etiam maximus
            lectus ac mauris consequat, in luctus tellus pharetra.
          </p>
        </div>
      </div>
    </div>
    <div class="w-[75%] flex flex-row py-5">
      <div class="w-[100%] flex flex-col gap-3">
        <div class="text-center">
          <h1 class="text-[17px] text-[#01060D] font-[500]">Size</h1>
          <h1 class="text-[45px] text-[#01060D] font-[500] mt-3">100</h1>
        </div>
        <div class="text-center">
          <h1 class="text-[17px] text-[#01060D] font-[500]">
            Estimated Minting Cost
          </h1>
          <h1 class="text-[45px] text-[#01060D] font-[500] mt-3">100</h1>
        </div>
      </div>
      <div class="flex flex-col w-[65%] gap-5 px-9 py-4">
        <button
          class="border-[1px] border-[#F2F2F2] bg-[#03162D] rounded-[6px] text-white py-3"
        >
          Add Data
        </button>
        <button
          class="border-[1px] border-[#A1AFCF] bg-[#A1AFCF] rounded-[6px] py-3"
        >
          View
        </button>
        <button class="border-[1px] border-[#596784] rounded-[6px] py-3">
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NFTsList",
  props: {
    Nftname: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    Token: {
      type: Number,
    },
  },
};
</script>
