<template>
  <div>
    <div
      class="bg-bodycolor flex justify-between items-center py-6 w-[100%] mt-4"
    >
      <div>
        <h1 class="text-[24px] text-[#FFFFFF] font-[600]">NFT List</h1>
      </div>
      <div
        class="bg-bodycolor flex gap-2 border-[1px] border-[#FFFFFF] px-4 py-2 rounded-[6px]"
      >
        <img src="../../assets/Template/ButtonIcon.svg" alt="ButtonIcon" />
        <button class="text-[white] w-[100%]">Create New</button>
      </div>
    </div>
    <div class="flex flex-col gap-8">
      <div>
        <NFTsList
          :Nftname="'NFT Name Here'"
          :name="'Author Name'"
          :Token="557"
        />
      </div>
      <div>
        <NFTsList
          :Nftname="'NFT Name Here'"
          :name="'Author Name'"
          :Token="557"
        />
      </div>
    </div>
  </div>
</template>
<script>
import NFTsList from "./NFTsList.vue";
export default {
  components: {
    NFTsList,
  },
};
</script>
