<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center py-12 lg:px-8 absolute top-0 left-0 w-[100%] h-[100vh] z-30 login_form_container"
    style="background: linear-gradient(180deg, #10dab9 0%, #04294f 100%)"
  >
    <div
      class="w-[430px] mx-auto px-[32px] py-[24px] bg-[#E0F2EE] rounded-[12px] login_form"
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          class="w-[159px] h-[65px] top-[15px] flex items-center justify-between mx-auto"
          src="../../assets/Auth/Logo.svg"
          alt="Filedgr"
        />
      </div>

      <div
        class="mt-7 w-[100%] bg-white shadow-md p-[36px] rounded-[6px] flex flex-col gap-[5px] from_container"
      >
        <h1
          class="text-center text-[27px] font-[700] leading-3 tracking-tight text-[#01060D]"
        >
          Welcome to Filedgr
        </h1>
        <p
          class="mt-3 text-center text-[16px] font-[300] leading-[24px] text-[#01060D] py-2 px-[8px]"
        >
          Let's make blockchain storage more rewarding.
        </p>
        <form class="space-y-6" @submit.prevent="HandleSubmit">
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-6 text-gray-900"
            ></label>
            <div class="mt-0">
              <input
                v-model="FormData.email"
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                required=""
                placeholder="Email Address"
                class="block px-[12px] outline-none w-full rounded-[6px] border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#273550] !text-[12px] sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block text-sm font-medium leading-6 text-gray-900"
            ></label>
            <div class="mt-2">
              <input
                v-model="FormData.password"
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required=""
                placeholder="Login password"
                class="block outline-none px-[12px] w-full rounded-[6px] border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#273550] !text-[12px] sm:leading-6"
              />
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input
                v-model="FormData.RememberMe"
                id="remember-me"
                name="remember-me"
                type="checkbox"
                class="h-4 w-4 rounded border-5 border-red-500 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                for="remember-me"
                class="ml-2 block text-[14px] font-[400] leading-6 text-[#273550]"
                >Remember me</label
              >
            </div>

            <div class="text-[14px] leading-6 text-decoration-line: underline">
              <a
                href="#"
                class="font-[400] text-[#273550] hover:text-indigo-500"
                >Forgot password?</a
              >
            </div>
          </div>

          <div>
            <button
              @click="login"
              class="flex w-full justify-center rounded-md bg-[#03B897] px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm"
            >
              Log In
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
export default {
  name: "Auth",
  setup() {
    const { loginWithRedirect } = useAuth0();

    const FormData = {
      email: "",
      password: "",
      RememberMe: false,
    };
    const HandleSubmit = () => {
      loginWithRedirect();
    };
    return {
      FormData,
      HandleSubmit,
    };
  },
};
</script>

<style scoped>
input {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
@media (max-width: 678px) {
  .login_form {
    width: 100%;
    padding: 20px;
  }
  .login_form_container {
    padding: 30px 10px !important;
  }
  .from_container {
    padding: 36px 10px;
  }
}

@media (max-width: 420px) {
  .from_container {
    gap: 5px;
    margin-top: 15px;
  }
  .from_container h1 {
    font-size: 30px !important;
  }
  .from_container p {
    font-size: 15px;
  }
  .from_container label {
    font-size: 13px;
  }
  .from_container a {
    font-size: 13px;
  }
  .login_form {
    padding: 20px 10px;
  }
}

@media (max-width: 350px) {
  .from_container h1 {
    font-size: 26px !important;
  }
  .from_container p {
    font-size: 13px;
  }
}
</style>
