<template>
  <div
    class="bg-bodycolor px-[100px] py-8 min-h-[150vh] flex flex-col Bannerheading"
  >
    <!-- <Banner /> -->
    <!-- <Activity /> -->
  </div>
</template>

<script>

export default {
  name: "Dashboard",
  components: {
  },
};
</script>
<style scoped>
@media (max-width: 1020px) {
  .Bannerheading {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 568px) {
  .Bannerheading {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
