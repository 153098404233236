<template>
  <div>
    <TempCreate
      :ISTemplateName="true"
      :IsVisible="false"
      :IsShowCustomFields="false"
      :IsShowAttachmentNote="true"
    />
  </div>
</template>

<script>
import TempCreate from "@/components/Templates/TemplatesCreate/TempCreate.vue";
export default {
  components: {
    TempCreate,
  },
};
</script>
