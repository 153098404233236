<template>
  <div class="bg-bodycolor h-auto px-[100px] flex flex-col Main_Comp">
    <div class="flex flex-col gap-2">
      <div class="px-12 mt-12 flex flex-wrap gap-2 button_comp">
        <button
          :class="[
            'w-[180px] h-[60px] rounded-[6px] border-none outline-none btn_comp',
            currentTemplateType === 'my'
              ? 'bg-[#E0F2EE] text-black '
              : 'bg-[#273550] text-white',
          ]"
          @click="toggleCreateTemplate('my')"
        >
          <p class="text-[16px] font-[600] heading_comp">My Templates</p>
        </button>
        <button
          :class="[
            'w-[180px] h-[60px] rounded-[6px] border-none outline-none btn_comp',
            currentTemplateType === 'public'
              ? 'bg-[#E0F2EE] text-black'
              : 'bg-[#273550] text-white',
          ]"
          @click="toggleCreateTemplate('public')"
        >
          <p class="text-[16px] font-[600] heading_comp">Public Templates</p>
        </button>
      </div>
      <div class="pl-12 Banner_Comp">
        <CreateTemplate :TemplateType="currentTemplateType" />
      </div>
      <div class="pl-12">
        <template v-if="currentTemplateType === 'public'">
          <template v-if="hasPublicTemplates">
            <PublicTemp />
          </template>
          <template v-else>
            <NoTemplateComponent />
          </template>
        </template>
        <template v-else>
          <NoTemplateComponent />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import NoTemplateComponent from "../../components/Templates/NoTemplateComponent.vue";
import CreateTemplate from "../../components/Templates/Banner/CreateTemplate.vue";
import PublicTemp from "../../components/Templates/PublicTemplate/PublicTemp.vue";

export default {
  name: "Templates",
  components: {
    CreateTemplate,
    NoTemplateComponent,
    PublicTemp,
  },
  data() {
    return {
      currentTemplateType: "my",
      hasPublicTemplates: false,
    };
  },
  methods: {
    toggleCreateTemplate(templateType) {
      this.currentTemplateType = templateType;

      if (templateType === "public") {
        this.hasPublicTemplates = true;
      } else {
        this.hasPublicTemplates = false;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 1020px) {
  .Main_Comp {
    padding-left: 0px;
    padding-right: 0px;
  }
  .Banner_Comp {
    padding-left: 20px;
    padding-right: 20px;
  }
  .button_comp {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 845px) {
  .Main_Comp {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .btn_comp {
    width: 48%;
  }
  .heading_comp {
    font-size: 14px;
  }
}
@media (max-width: 330px) {
  .heading_comp {
    font-size: 12px;
  }
}
@media (max-width: 275px) {
  .heading_comp {
    font-size: 10px;
  }
}
</style>
