<template>
  <div
    id="app"
    :class="{ 'overflow-y-hidden h-[100vh] notFoundContainer': isNotFound }"
  >
    <Header v-if="HideHeader" />
    <router-view />
    <Footer v-if="HideFooter" />
  </div>
</template>

<script>
import Header from "./components/shared/Header.vue";
import Footer from "./components/shared/Footer.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  setup() {
    const route = useRoute();
    const isNotFound = computed(() => route.name === "NotFound");
    let HideFooter = computed(() => route.name !== "NotFound");
    const HideHeader = computed(() => route.name !== "Auth");
    HideFooter = computed(
      () => route.name !== "Auth" && route.name !== "NotFound"
    );
    return { HideHeader, isNotFound, HideFooter };
  },
};
</script>

<style>
@media (max-width: 850px) {
  .notFoundContainer {
    height: auto !important;
    background-color: white !important;
  }
}
</style>
