import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/NotFound.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import Templates from "../views/dashboard/Templates.vue";
import Auth from "../views/auth/Auth.vue";
import TemplatesCreate from "../views/template/TemplatesCreate.vue";
import PublicTemplate from "../views/template/PublicTemplate.vue";
import NFTs from "../views/dashboard/NFTs.vue";
import NFTtemplate from "../views/NFTs/NFTtemplate.vue";

const routes = [
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/login",
    name: "Auth",
    component: Auth,
  },
  {
    path: "/templates",
    name: "Templates",
    component: Templates,
  },
  {
    path: "/createtemplate",
    name: "TemplatesCreate",
    component: TemplatesCreate,
  },
  {
    path: "/publictemplate",
    name: "PublicTemplate",
    component: PublicTemplate,
  },
  {
    path: "/nfts",
    name: "NFTs",
    component: NFTs,
  },
  {
    path: "/nftscreate",
    name: "NFTtemplate",
    component: NFTtemplate,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
