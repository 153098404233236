<template>
  <div class="h-[144px] bg-[#12A78E] w-[100%] rounded-[12px] pl-7">
    <div
      class="w-[100%] h-[144px] bg-[#E0F2EE] flex flex-row justify-between rounded-r-[12px] py-4 px-4"
    >
      <div class="flex flex-col gap-1 w-[50%]">
        <div class="flex flex-row gap-2 place-items-center">
          <h1 class="text-[22px] text-[#01060D] font-[600]">
            {{ name }}
          </h1>
          <img src="../../../assets/Template/Arrow.svg" alt="Arrow" />
        </div>
        <h2 class="text-[16px] font-[400] text-[#273550]">
          Template Description Goes Here
        </h2>
        <div class="flex flex-row gap-2 mt-2">
          <p class="text-[16px] font-[500] text-[#273550]">Created on</p>
          <h1 class="text-[16px] font-[600] text-[#0D0D0D]">
            {{ Date }}
          </h1>
        </div>
      </div>
      <div class="flex justify-between place-items-center w-[50%]">
        <div class="flex w-[100%] gap-[30px]">
          <div class="py-3 w-[30%] flex flex-col gap-1">
            <div class="flex flex-row gap-2 place-items-center">
              <img
                src="../../../assets/Template/Size.svg"
                alt="Size"
                class="w-[24px] h-[24px]"
              />
              <p class="text-[14px] font-[400] text-[#273550]">Size</p>
            </div>
            <p class="flex text-[36px] font-[500] text-[#01060D] text-center">
              {{ SizeValue }}
            </p>
          </div>
          <div class="py-3 flex flex-col gap-1">
            <div class="flex flex-row gap-2 place-items-center">
              <img
                src="../../../assets/Template/Cost.svg"
                alt="Cost"
                class="w-[21.6px] h-[16.8px]"
              />
              <p class="text-[14px] font-[400] text-[#273550]">Minting Cost</p>
            </div>
            <p
              class="text-[36px] font-[500] text-[#01060D] text-center w-[100%]"
            >
              {{ CostValue }}
            </p>
          </div>
        </div>

        <div class="w-[35%] flex flex-col gap-2">
          <div
            class="h-[32px] border-[1px] rounded-[6px] bg-[#03B897] flex justify-center place-items-center"
          >
            <button class="w-[100%] text-[16px] text-[#FFFFFF] font-[600]">
              Add NFT
            </button>
          </div>
          <div class="flex flex-row">
            <div
              class="h-[32px] border-[1px] rounded-[6px] bg-[#273550] flex w-[100%] place-items-center justify-center gap-1"
            >
              <button class="text-[16px] text-[#FFFFFF] font-[600]">
                Edit
              </button>
              <img
                src="../../../assets/Template/edit.svg"
                alt="edit"
                class="w-[16px] h-[16px]"
              />
            </div>
          </div>
          <div
            class="h-[32px] border-[1px] rounded-[6px] border-[#273550] text-[black] flex justify-center place-items-center"
          >
            <button class="w-[100%] text-[16px] font-[600] text-[#273550]">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "publicComp",
  props: {
    SizeValue: {
      type: Number,
      default: 148,
    },
    CostValue: {
      type: Number,
      default: 42,
    },
    name: {
      type: String,
      default: "",
    },
    Date: {
      type: String,
      default: "",
    },
  },
};
</script>
