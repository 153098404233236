<template>
  <div class="bg-bodycolor w-[100%] h-[342px] mt-[70px]">
    <div class="w-[100%] h-[156px]">
      <img
        class="w-[100%] h-[156px] rounded-[12px]"
        src="../../assets/Template/Nocomments.svg"
        alt="Nocomments"
      />
    </div>
    <div class="text-[#FFFFFF] flex flex-col text-center">
      <h2 class="text-[24px] font-[500]">No Templates Here Yet</h2>
      <p class="text-[18px] font-[400] mt-4" style="letter-spacing: 1px">
        Once you've created and launched a Template,
      </p>
      <p class="text-[18px] font-[400] mt-4" style="letter-spacing: 1px">
        we'll track the activity here.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "NoTemplateComponent",
};
</script>
