<template>
  <div class="bg-bodycolor min-h-[170vh] px-[100px] flex flex-col">
    <div class="flex flex-col mt-16 px-15">
      <CreateTemplate TemplateType="Create a New NFT" />
    </div>
    <div v-if="!ShowNoTemplate">
      <NoTemplateComponent />
    </div>
    <div v-else>
      <NFTsComp TemplateType="All About NFTs" />
    </div>
  </div>
</template>
<script>
import CreateTemplate from "../../components/Templates/Banner/CreateTemplate.vue";
import NFTsComp from "../../components/NFTs/NFTsComp.vue";
import NoTemplateComponent from "../../components/Templates/NoTemplateComponent.vue";
export default {
  name: "NFTs",
  components: {
    CreateTemplate,
    NoTemplateComponent,
    NFTsComp,
  },
  data() {
    return {
      ShowNoTemplate: true,
    };
  },
};
</script>
