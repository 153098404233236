<template>
  <footer class="bg-[#01060D] pb-[10px]" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto max-w-7xl px-6 pb-8 pt-20 sm:pt-24 lg:px-8 lg:pt-32">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8 max-w-[1000px] mx-auto">
        <div class="grid grid-cols-1 gap-8 xl:col-span-2">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <ul role="list" class="mt-0 space-y-1">
                <li v-for="item in navigation.company" :key="item.name">
                  <a
                    :href="item.href"
                    class="text-[16px] leading-6 text-gray-300 hover:text-white"
                    >{{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <ul role="list" class="mt-0 space-y-1">
                <li v-for="item in navigation.legal" :key="item.name">
                  <a
                    :href="item.href"
                    class="text-[16px] leading-6 text-gray-300 hover:text-white"
                    >{{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mt-10 xl:mt-0">
          <h3 class="text-[18px] font-semibold leading-6 text-white">
            Connect Filedgr!
          </h3>
          <p class="mt-2 text-[16px] leading-6 text-gray-300">
            Get the latest on new brand partners, product launches and more.
            We’ll never sell your information (promise).
          </p>
          <form class="mt-6 sm:flex sm:max-w-md">
            <div class="w-[100%] relative">
              <input
                type="email"
                name="email-address"
                id="email-address"
                autocomplete="email"
                required=""
                class="w-[100%] min-w-0 appearance-none rounded-[2px] border-0 bg-white px-3 py-[9px] text-[14px] text-[#A8ABB2] shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 outline-none sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                placeholder="Enter your here"
              />
              <img
                src="../../assets/svg/vector.svg"
                alt=""
                class="absolute top-1/2 transform -translate-y-1/2 right-3"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
const navigation = {
  company: [
    { name: "About Us", href: "#" },
    { name: "Contact Us", href: "#" },
    { name: "Help Center", href: "#" },
    { name: "Terms & Conditions", href: "#" },
    { name: "Privacy Policy", href: "#" },
  ],
  legal: [
    { name: "FAQs", href: "#" },
    { name: "Newsroom", href: "#" },
    { name: "Careers", href: "#" },
    { name: "Investors", href: "#" },
    { name: "Accessibility", href: "#" },
    { name: "CA Transparency Act", href: "#" },
  ],
};
</script>

<script></script>
