<template>
  <div class="bg-bodycolor h-auto flex flex-col items-center py-5">
    <div class="bg-bodycolor flex justify-between items-center py-6 w-[100%]">
      <h2 class="text-[26px] font-[500] text-white">Public Templates</h2>
      <div
        class="text-white bg-bodycolor px-4 py-2 rounded-[6px] border-[1px] border-[#FFFFFF] flex gap-2"
      >
        <img src="../../../assets/Template/ButtonIcon.svg" alt="" />
        <button class="w-[100%]">createNFT</button>
      </div>
    </div>
    <div class="w-[100%] flex flex-col gap-5">
      <publicComp
        :SizeValue="128"
        :CostValue="42"
        :name="'Template Name Here'"
        :Date="'February 1, 2023'"
      />
      <publicComp
        :SizeValue="234"
        :CostValue="65"
        :name="'Template Name Here'"
        :Date="'February 1, 2023'"
      />
      <publicComp
        :SizeValue="12"
        :CostValue="4"
        :name="'Template Name Here'"
        :Date="'February 1, 2023'"
      />
    </div>
  </div>
</template>

<script>
import publicComp from "../../../components/Templates/PublicComp/publicComp.vue";

export default {
  name: "PublicTemp",
  components: {
    publicComp,
  },
};
</script>
