<template>
  <div
    class="flex w-[100%] min-h-[180px] h-auto bg-[#E0F2EE] rounded-[8px] justify-between overflow-hidden main_templete_container"
  >
    <div class="w-[100%] h-[100%] banner_comp">
      <div class="py-5 h-[100%] px-8 flex flex-col Div_comp">
        <h2 class="text-[#01060D] text-[22px] font-[600] heading_comp">
          {{
            TemplateType === "my"
              ? "Create a New Template"
              : TemplateType === "public"
              ? "Public Template"
              : "Create a New NFT"
          }}
        </h2>
        <p class="text-[#273550] text-[14px] font-[400] mt-2 Text_comp">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam maximus
          lectus ac mauris consequat, in luctus tellus pharetra.
        </p>
        <div class="w-[100%] mt-5">
          <router-link
            :to="
              TemplateType === 'my'
                ? '/createtemplate'
                : TemplateType === 'public'
                ? '/publictemplate'
                : '/nftscreate'
            "
            class="bg-[#03B897] rounded-[6px] text-[#FFFFFF] text-[14px] font-[500] py-2 px-12 inline-block"
          >
            <button
              class="h-full bg-transparent cursor-pointer focus:outline-none button_text"
            >
              {{
                TemplateType === "my" || TemplateType === "public"
                  ? "Create Template"
                  : "Create Template"
              }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="w-[100%] h-[100%] relative ml-[43px] img_comp">
      <img
        class="w-[554px] absolute h-[250px] top-0 right-0"
        src="../../../assets/Template/images/banner-template.png"
        alt="Banner"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateTemplate",
  props: {
    TemplateType: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
@media (max-width: 830px) {
  .banner_comp {
    width: 1300px;
  }
}
@media (max-width: 610px) {
  .banner_comp {
    padding-top: 0;
    padding-bottom: 0;
  }
  .img_comp {
    display: none;
  }
}

@media (max-width: 500px) {
  .main_templete_container {
    min-height: auto !important;
  }
  .Div_comp {
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>
