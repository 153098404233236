<template>
  <div>
    <TempCreate />
  </div>
</template>

<script>
import TempCreate from "../../components/Templates/TemplatesCreate/TempCreate.vue";
export default {
  components: {
    TempCreate,
  },
};
</script>
