import { createApp } from "vue";
// import { createAuth0 } from "@auth0/auth0-vue";
import App from "./App.vue";
import router from "./router";
import "./global.css";
import InlineSvg from "vue-inline-svg";

const app = createApp(App);

app.use(router);

// app.use(
//   createAuth0({
//     domain: "{}",
//     clientId: "{}",
//     authorizationParams: {
//       redirect_uri: window.location.origin,
//     },
//   })
// );

app.component("inline-svg", InlineSvg);

app.mount("#app");
