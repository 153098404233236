<template>
  <section class="text-gray-600 body-font h-[90vh] notfound_main_container">
    <div
      class="mx-auto flex md:flex-row flex-col justify-center items-center h-[100%] pic_container"
    >
      <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
        <img
          class="object-cover object-center rounded"
          alt="hero"
          src="..\assets\NotFound\NotFound.svg"
        />
      </div>
      <div
        class="max-w-[631px] lg:flex-grow lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center notFountContentarea"
      >
        <h1
          class="title-font text-[43px] mb-4 font-[500] text-gray-900 leading-[60px] max-w-[631px]"
        >
          The page you're looking for isn't here.
        </h1>
        <p class="mb-8 text-[16px] leading-[30px] max-w-[520px]">
          If you think something isn't working right, please report the issue
          and we'll take a look. Thanks so much!
        </p>
        <div class="flex gap-3 notFount_btn">
          <button
            click="goBack"
            class="bg-[#10DAB9] text-white px-[20px] py-[9px] rounded-[6px] w-[170px] h-[45px] text-[14px] font-[600] flex items-center justify-center space-x-[8px]"
          >
            <img
              src="..\assets\NotFound\arrow.svg"
              alt="Left Arrow"
              class="h-[15px]"
            />
            <span>Go Back</span>
          </button>

          <button
            @click="reportIssue"
            class="bg-[#9B9B97] text-white px-[20px] py-[9px] rounded-[6px] w-[170px] h-[45px] text-[14px] font-[600]"
          >
            Report
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
@media screen and (max-width: 850px) {
  .notfound_main_container {
    height: 100% !important;
  }
  .pic_container {
    flex-direction: column;
    width: 100%;
    height: auto !important;
    background-color: white;
    position: relative;
    margin-top: 50px;
  }
  .notFountContentarea {
    max-width: 100% !important;
    background-color: white;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 550px) {
  .notFountContentarea h1 {
    font-size: 7vw;
    line-height: 10vw;
  }
  .notFountContentarea p {
    font-size: 15px;
    line-height: 25px;
  }
}

@media (max-width: 370px) {
  .notFount_btn {
    flex-direction: column;
    width: 100%;
  }
  .notFount_btn button {
    width: 100%;
  }
}
</style>
