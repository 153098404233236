<template>
  <div
    class="bg-[white] flex flex-row w-[100%] border-[1px] border-[#D7E5FF] py-2 px-4 rounded-[6px]"
  >
    <div class="w-[100%] h-[128px] flex flex-row">
      <div class="w-[100%] flex">
        <div class="w-[100%]">
          <h1 class="text-[20px] text-[#01060D] font-[500]">Attachment Note</h1>
          <p class="text-[14px] text-[#273550] font-[500] mt-2">
            Attached by: Aightek
          </p>
          <div class="flex gap-2 mt-12">
            <p class="text-[16px] text-[#273550] font-[600]">Created on</p>
            <h1 class="text-[#0D0D0D] text-[16px] font-[600]">
              February 1, 2023
            </h1>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-[100%] gap-6">
        <h2 class="text-[18px] text-[#273550] font-[500]">TX Hash:</h2>
        <h2 class="text-[18px] text-[#273550] font-[500]">Number of files:</h2>
        <h2 class="text-[18px] text-[#273550] font-[500]">
          Attachment Volume of Files:
        </h2>
      </div>
    </div>
    <div class="w-[60%] flex place-items-center justify-end">
      <div class="border-[1px] border-[#03162D] rounded-[6px] px-7 py-1">
        <button class="text-[#03162D] text-[14px] font-[600] text-center">
          inspect
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
